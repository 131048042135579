<template>
  <v-card class="pa-3">
    <v-card-title> Possible slots: </v-card-title>
    <v-btn class="primary" @click="newSlot()">Add new slot</v-btn>
    <div v-for="(s, index) in value" :key="index">
      <v-text-field label="Slot name" v-model="s.sName"></v-text-field>
      <v-select :items="centri" label="Slot centre" v-model="s.centre"></v-select>
      <v-text-field label="Slot date and time description" v-model="s.dt"></v-text-field>
      <v-text-field label="Slot max students" v-model="s.maxStudents"></v-text-field>
      <v-btn class="critical" @click="removeSlot(index)"><v-icon>mdi-delete</v-icon></v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      centri: ["Sigečica", "Kruge", "Vrbik"],
    };
  },
  methods: {
    newSlot() {
      this.value.push({
        sName: "",
        centre: "",
        dt: "",
        maxStudents: "",
        sPolaznici: [],
      });
    },
    removeSlot(index) {
      this.value.splice(index, 1);
      if (this.value.length <= 0) {
        this.value.push({
          sName: "",
          centre: "",
          dt: "",
          maxStudents: "",
          sPolaznici: [],
        });
      }
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
};
</script>
