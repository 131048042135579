<template>
  <v-card class="mx-auto pa-3" max-width="700">
    <v-card-title>Create an event</v-card-title>
    <v-text-field label="Event Name" v-model="eName"></v-text-field>
    <v-text-field label="Event image link" v-model="imgLink"></v-text-field>
    <v-text-field label="Event title" v-model="eTitle"></v-text-field>
    <v-text-field label="Event text" v-model="eText"></v-text-field>
    <my-slots v-model="eSlots" class="my-3"></my-slots>

    <v-btn class="primary ma-2" @click="dodajEvent()" v-if="dispSubmit"> <v-icon>mdi-content-save</v-icon> Save event</v-btn>
    <v-btn to="/selectActive" class="info ma-2">Select active event</v-btn>
    <v-alert class="my-2" type="success" v-if="!dispSubmit">Event has been saved!</v-alert>
  </v-card>
</template>

<script>
import db from "../firebaseInit.js";

import MySlots from "../components/MySlots.vue";
export default {
  components: {
    MySlots,
  },
  data() {
    return {
      eName: "",
      imgLink: "",
      eTitle: "",
      eText: "",
      eSlots: [],
      dispSubmit: true,
    };
  },
  methods: {
    async dodajEvent() {
      //var ref = db.collection("eventList").doc(this.eName).collection("termini").doc(this.selectedTermin.id);
      //console.log(this.eName);
      await db.collection("eventList").doc(this.eName).set({ imglink: this.imgLink, text: this.eText, title: this.eTitle });
      for (let s of this.eSlots) {
        //console.log(s);
        await db.collection("eventList").doc(this.eName).collection("termini").doc(s.sName).set({
          naziv: s.sName,
          centar: s.centre,
          datum: s.dt,
          max: s.maxStudents,
          polaznici: [],
        });
      }
      this.dispSubmit = false;
    },
  },
};
</script>
